.pageNumberDisplay {
	position: fixed;
	bottom: 0px;
	right: 10%;
}

.pagebtn {
	margin: 2px;
	padding: 5px;
	z-index: 10;
}
.pagebtn.active {
    border: 1px solid black;
	background-color: whitesmoke;
	color: black;
}

.pagebtn.inactive {
	border: 1px solid grey;
	background-color: lightgrey;
	color: grey;
}