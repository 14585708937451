.orderDiv {
    text-align: center;
}

.orderElementDiv {
    width: 50%;
    margin: auto;
    text-align: center;
}

table {
	position: relative;
    width: 100%;
    background-color: lightgrey;
    margin: auto;
    text-align: left;
}

table td {
    vertical-align: top;
    padding: 10px;
    /* border: 1px solid red; */
}

.refreshbtn {
    position: absolute;
    right: 20px;
    border: 1px solid;
    padding: 20px;
}

/* Dropdown Button */
.dropbtn {
    background-color: transparent;
    color: black;
    padding: 10px;
    font-size: 16px;
    border: none;


}
/* The container <div> - needed to position the dropdown content */
.dropdown {
    display: inline-block;
	position: absolute;
	top: 1px;
	right: 1px;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {

    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #ddd;}