td {
	display: inline-block;
}

.productContainer {
    position: relative;
    text-align: center;
    background-color: lightgrey;
    margin: auto;
    margin-top: 10px;
    min-width: 41%;
	width: fit-content;
}


.inputDivider {
    border-right: 1px solid black;
    display: inline-block;
	width: 25%;
}

.pricesDivider {
	width: fit-content;
}

.top-right {
	position: absolute;
	top: 0px;
	right: 0px;
}

.bot-right {
	position: absolute;
	bottom: 0px;
	right: 0px;
}

.btn {
	margin: 2px;
	padding: 5px;
	z-index: 10;
}

.btn.active {
    border: 1px solid black;
    background-color: lightblue;
}

.btn.inactive {
	border: 1px solid grey;
	background-color: lightgrey;
	color: grey;
}

.btn.active:hover {
    background-color: aqua;
}

.btn.delete {
	border: 1px solid black;
    background-color: lightgrey;
}

.btn.delete:hover {
	background-color: lightcoral;
}

.subproductSelectorDiv {
	float: left;
	width: fit-content;
}

.fileUploadBox {
	width: 5em;
}

.border {
	border: 1px solid red;
}