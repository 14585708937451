.header {
    text-align: center;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    /* bottom: -1px; */
    position: relative;
    list-style: none;
    padding: 12px 48px;
    cursor: pointer;
}

.react-tabs__tab--selected {
    background-color: antiquewhite;
    border: 1px solid transparent;
}

.react-tabs__tab-list {
    margin: 0;
    border-bottom: 1px solid gray;
}

.react-tabs__tab-panel {
}

.react-tabs__tab-panel--selected {
	border-top: 1px solid gray;
}